@layer components {
  .rx-label {
    @apply text-sm font-medium text-slate-light uppercase;
  }

  .rx-label.custom-content::after {
    content: '*';
    color: #4f92ff;
  }

  .rx-input-wrapper {
    @apply relative;
  }

  .rx-input {
    @apply relative inline-flex w-full items-center space-x-2 px-6 py-3 border border-iceberg-dark text-base rounded-full text-dark transition-shadow duration-200;
  }

  .rx-input:disabled {
    @apply text-slate-light;
  }

  .rx-input:focus {
    @apply outline-none ring-2 ring-blue-bright border-transparent;
  }

  .rx-input.error {
    @apply border-red-orange text-red-orange placeholder-red-orange;
  }

  .rx-input.error:focus {
    @apply ring-2 ring-red-orange border-transparent;
  }

  .rx-input.search {
    @apply pr-48;
  }

  .rx-input.search + .search-button {
    @apply absolute right-0 h-full pl-5 pr-2 flex items-center gap-4 text-blue-bright border-l border-iceberg-dark font-medium text-base transition-colors duration-200;
  }

  .rx-input.search + .search-button.label {
    @apply pl-10 pr-10;
  }

  .rx-input.search:focus + .search-button {
    @apply border-blue-bright border-l-2;
  }

  .rx-input.search::placeholder {
    @apply italic;
  }

  .validation-message {
    @apply mt-2 text-sm;
  }

  .validation-message.error {
    @apply pl-6 text-red-orange;
  }

  .rx-input-date-wrapper {
    @apply mt-0;
  }

  .rx-input-date-wrapper .validation-message.error {
    @apply pl-3;
  }

  .rx-text-area {
    @apply relative inline-flex w-full items-center space-x-2 px-6 py-3 border border-iceberg-dark text-base rounded-xl text-dark transition-shadow duration-200;
  }

  .rx-text-area:disabled {
    @apply text-slate;
  }

  .rx-text-area:focus {
    @apply outline-none ring-2 ring-blue-bright border-transparent;
  }

  .rx-text-area.error {
    @apply border-red-orange text-red-orange placeholder-red-orange;
  }

  .rx-text-area.error:focus {
    @apply ring-2 ring-red-orange border-transparent;
  }

  .rx-static-field span {
    @apply text-label font-medium text-sm text-slate-light uppercase;
  }

  .rx-static-field > div {
    @apply text-slate;
  }

  .rx-static-field.phone > div {
    @apply text-blue-bright;
  }

  .rx-switchbox {
    @apply flex flex-col relative;
  }

  .rx-switchbox .label {
    @apply text-dark text-base transition-colors duration-200;
  }

  .rx-switchbox .label.disabled {
    @apply text-slate-light;
  }

  .rx-switchbox .switch-button {
    @apply bg-slate-light relative inline-flex flex-shrink-0 h-5 w-10 p-[0.125rem] rounded-full cursor-pointer transition-colors ease-in-out duration-200;
  }

  .rx-switchbox .switch-button.enabled {
    @apply bg-seaweed;
  }

  .rx-switchbox .switch-button:focus {
    @apply outline-none border-transparent;
  }

  .rx-switchbox .switch-button .switcher {
    @apply translate-x-0 pointer-events-none inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200;
  }

  .rx-switchbox .switch-button[disabled] .switcher {
    @apply bg-iceberg-badge;
  }

  .rx-switchbox .switch-button .switcher {
    @apply bg-white;
  }

  .rx-switchbox .switch-button.enabled .switcher {
    @apply translate-x-5;
  }

  .rx-switchbox.button .rx-label {
    @apply -top-6;
  }

  .rx-list-wrapper {
    @apply absolute hidden mt-1 w-full bg-white rounded-md text-slate overflow-hidden
      border border-iceberg-dark sm:text-sm opacity-0 z-40 transition ease-in duration-100;
  }

  .rx-list-wrapper:focus {
    @apply outline-none;
  }

  .rx-list-wrapper.open {
    @apply block opacity-100;
  }

  .rx-list-wrapper input {
    @apply border-0 border-b border-iceberg-dark text-sm pl-6 rounded-t-md;
  }


  .rx-list-wrapper input:focus {
    @apply border-iceberg-dark ring-0;
  }

  .rx-list-wrapper .empty-list {
    @apply py-3 pl-6 pr-4;
  }

  .rx-select-list {
    @apply max-h-60 overflow-auto overscroll-contain outline-none;
  }

  .rx-select-list .rx-select-item {
    @apply text-slate cursor-default font-normal text-sm select-none relative py-3 pl-8 pr-4;
  }

  .rx-select-list .rx-select-item.selected {
    @apply font-semibold bg-iceberg;
  }

  .rx-select-list .rx-select-checkmark {
    @apply text-blue-bright absolute inset-y-0 left-0 flex items-center pl-1.5;
  }

  .rx-select-button {
    @apply relative w-full bg-white border border-iceberg-dark rounded-full pl-6 pr-8 py-3
      text-left cursor-pointer text-base;
  }

  .rx-select-button.sm {
    @apply pl-4 pr-10 py-1;  
  }

  .rx-select-button:focus {
    @apply outline-none ring-1 ring-blue-bright border-blue-bright;
  }

  .rx-select-button.error {
    @apply ring-red-orange border-red-orange text-red-orange;
  }

  .rx-icon {
    @apply absolute z-10 inset-y-0  flex items-center text-iceberg pointer-events-none text-slate;
  }

  .rx-icon:focus {
    @apply outline-none;
  }

  .rx-icon.right {
    @apply right-0 pr-4;
  }

  .rx-checkbox {
    @apply flex items-center py-3;
  }

  .checkbox {
    /* @apply ml-0.5 mt-0.5 mr-2 text-blue-bright rounded-sm ring-2 ring-offset-2 ring-iceberg-dark; */
    margin-left: 0.125rem;  
    margin-top: 0.125rem;   
    margin-right: 0.5rem;  
    color: #0364ff;        
    border-radius: 0.125rem; 
    box-shadow: 0 0 0 2px #e2e3e8, 0 0 0 4px #ffffff; 
  }
  
  .checkbox:focus-visible {
    @apply outline-none;
  }

  .checkbox.darker  {
    /* @apply ring-slate; */
    box-shadow: 0 0 0 2px #535875;
  }

  .checkbox.error {
    @apply ring-red-orange;
  }

  .rx-upload {
    @apply hidden;
  }

  .rx-input-wrapper .custom-style {
    line-height: 18px;
    font-weight: 400;
    border-color: rgba(128, 164, 214, 1);
  }
}
