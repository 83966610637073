@layer components {
  .badge {
    @apply flex flex-col border border-slate-light bg-iceberg-dark rounded;
  }

  .badge.active,
  .badge.confirmed {
    @apply border-blue-bright bg-blue-bright/10;
  }

  .badge.active {
    @apply text-white bg-blue-bright border-blue-bright/10;
  }

  .badge.error {
    @apply border-red-orange bg-red-orange/10;
  }

  .badge.warning {
    @apply border-orange bg-orange/10;
  }

  .badge .title {
    @apply font-bold text-dark;
  }

  .badge.active .title {
    @apply font-bold text-white;
  }

  .badge .subtitle {
    @apply font-medium text-dark;
  }

  .badge.active .subtitle {
    @apply font-bold text-white;
  }
}
