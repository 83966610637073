@layer components {
  .list-container .button-new {
    @apply rx-button px-8 py-2 leading-6 text-base font-medium;
  }

  .list-container.schedule h3 {
    @apply text-slate-light uppercase;
  }

  .list-container.schedule .button-new {
    @apply uppercase text-blue-bright px-0 py-0 border-none bg-transparent font-normal;
  }

  .list-container.schedule thead {
    @apply hidden;
  }

  .list-container.schedule tr {
    @apply shadow-transparent;
  }

  .list-container.schedule tr .drag-icon,
  .list-container.schedule tr .menu-name,
  .list-container.schedule tr .schedule-name {
    @apply transition-colors duration-200;
  }

  .list-container.schedule tr:hover .drag-icon,
  .list-container.schedule tr:hover .menu-name,
  .list-container.schedule tr:hover .schedule-name {
    @apply text-blue-bright;
  }

  .list-container.schedule tr .action {
    @apply text-slate-light opacity-100 md:opacity-0 transition-all duration-200;
  }

  .list-container.schedule tr:hover .action {
    @apply opacity-100;
  }

  .list-container.schedule tr .action:hover {
    @apply text-blue-bright;
  }

  .list-container.schedule tr .action.danger:hover {
    @apply text-red-orange;
  }

  .list-container.schedule td {
    @apply pr-0 py-2 md:py-4;
  }

  .list-container.schedule td:first-child {
    @apply md:pl-0;
  }

  .list-container.schedule .menu-name {
    @apply text-xs text-slate-light;
  }
}
