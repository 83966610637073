@layer components {
  .zoom-container {
    @apply my-4 flex items-center justify-center md:justify-start items-center justify-end gap-1;
  }

  .zoom-container .zoom {
    @apply flex items-center justify-center basis-12 h-12 box-content rounded-sm md:rounded-lg text-dark md:text-slate font-medium text-xs border border-transparent transition-colors duration-200 bg-iceberg md:bg-transparent;
  }

  .zoom-container .zoom:hover {
    @apply bg-iceberg text-dark;
  }

  .zoom-container .zoom[disabled] {
    @apply text-slate-light cursor-default;
  }

  .zoom-container .zoom[disabled] {
    @apply bg-transparent;
  }

  .zoom-container .zoom:focus-visible {
    @apply outline-none;
  }
}
