@layer components {
  body {
    @apply text-dark text-sm;
  }

  a {
    @apply text-blue-bright transition-colors duration-200;
  }

  a:focus {
    @apply outline-none;
  }

  .table-link {
    @apply text-slate-light transition-colors duration-200;
  }

  .table-link:active,
  .table-link:hover {
    @apply text-blue-bright;
  }

  h1 {
    @apply text-xl font-bold;
  }

  h2 {
    @apply text-2xl font-bold leading-7;
  }

  h3 {
    @apply text-xl font-bold;
  }

  h4 {
    @apply text-lg font-bold;
  }

  hr {
    @apply text-iceberg-dark;
  }

  .description {
    @apply text-slate;
  }
}
