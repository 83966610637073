@layer components {
  .rx-button {
    @apply inline-flex items-center justify-center md:justify-start px-10 py-3 border border-transparent text-base leading-5 font-medium rounded text-white bg-blue-bright w-full md:w-auto transition-colors duration-200;
  }

  .rx-button.md {
    @apply px-8 py-1 text-sm font-normal;
  }

  .rx-button.sm {
    @apply px-4 py-2 pr-2 text-sm font-normal;
  }

  .rx-button.sm-nav {
    @apply px-4 py-2 pr-4;
  }

  .rx-button.md.toolbar {
    @apply px-1 sm:px-8 w-auto;
  }

  .rx-button:hover {
    @apply bg-blue-light;
  }

  .rx-button:focus {
    @apply outline-none;
  }

  .rx-button:disabled {
    @apply cursor-default bg-iceberg-badge text-slate;
  }

  .rx-button:disabled:hover {
    @apply bg-iceberg-badge;
  }

  .rx-button.secondary {
    @apply bg-white text-blue-bright border border-blue-bright gap-2;
  }

  .rx-button.secondary:hover {
    @apply bg-iceberg-badge;
  }

  .rx-button.secondary:disabled {
    @apply bg-white border-slate text-slate;
  }

  .rx-button.secondary:disabled:hover {
    @apply bg-white;
  }

  .rx-button.danger {
    @apply bg-white text-red-orange border border-red-orange;
  }

  .rx-button.danger:hover {
    @apply bg-red-light;
  }

  .rx-button.alternate {
    @apply bg-slate text-white;
  }

  .rx-button.alternate:disabled {
    @apply bg-white border-slate text-slate;
  }

  .rx-button.alternate:hover {
    @apply bg-white border-slate text-slate;
  }

  .rx-button.custom-variant {
    @apply bg-white text-[#686d86];
  }

  .toolbar-button {
    @apply text-slate-light bg-iceberg p-2 rounded border border-iceberg-dark transition-colors duration-200;
  }

  .toolbar .toolbar-button {
    @apply rounded-l-none rounded-r-none;
  }

  .toolbar .toolbar-button:first-child {
    @apply rounded-l;
  }

  .toolbar .toolbar-button:last-child {
    @apply rounded-r;
  }

  .toolbar-button:hover {
    @apply text-blue-bright;
  }

  .toolbar-button:focus-visible {
    @apply outline outline-2 outline-blue-bright;
  }

  .toolbar-button.active {
    @apply text-white bg-blue-bright border-blue-bright;
  }

  .action-button {
    @apply inline-flex items-center justify-center md:justify-start text-slate-light transition-colors duration-200;
  }

  .action-button:focus-visible {
    @apply outline-none;
  }

  .action-button:hover {
    @apply text-blue-bright;
  }

  .action-button.warning:hover {
    @apply text-red-orange;
  }

  .action-button[disabled]:hover {
    @apply text-slate-light;
  }

  .action-button::after {
    @apply content-['|'] ml-2 font-normal text-slate-light;
  }

  .action-button.no-delimiter::after {
    @apply content-none;
  }

  .action-button:last-child::after {
    @apply content-none;
  }
}
