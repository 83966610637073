@layer utilities {
  .tooltip-center {
    transform: translateY(calc(-100% + .75rem)) translateX(calc(-50% + 10px));
  }

  .tooltip-no-center {
    transform: translateY(calc(-100% + .75rem)) translateX(0);
  }
}

@layer components {
  .rx-tooltip {
    @apply absolute hidden max-w-[15rem] bg-transparent text-slate p-4 pt-2 text-sm font-normal rounded z-30 bg-none bg-contain bg-no-repeat;
    transform: translateY(calc(-100% - .5rem)) translateX(1.5rem);
  }

  .rx-tooltip.wide {
    @apply max-w-[30rem];
    transform: translateY(calc(-100% - .5rem)) translateX(-20%);
  }

  .rx-checkbox .rx-tooltip {
    @apply max-w-[10rem];
    transform: translateY(calc(-100% + 1.5rem)) translateX(0);
  }

  .rx-switchbox .rx-tooltip {
    transform: translateY(calc(-100% + .5rem)) translateX(0);
  }

  .action-button .rx-tooltip {
    @apply tooltip-no-center md:tooltip-center;
  }

  .rx-tooltip.active {
    @apply block;
  }
}
