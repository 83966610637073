@layer components {
  .rx-button-switch {
    @apply relative;
  }

  .rx-button-switch .rx-label {
    @apply -top-[.875rem];
  }

  .rx-button-switch .switch-button {
    @apply relative inline-flex flex-shrink-0 h-[2.875rem] w-1/2 items-center justify-center p-3 border border-transparent leading-5 rounded-sm text-white transition transition-colors duration-200;
  }

  .rx-button-switch.enabled .switch-button {
    @apply text-dark;
  }

  .rx-button-switch .switch-button:last-child {
    @apply text-dark;
  }

  .rx-button-switch.enabled .switch-button:last-child {
    @apply text-white;
  }

  .rx-button-switch .switch-button:focus {
    @apply outline-none ring-0 ring-transparent border-0;
  }

  .rx-button-switch .switcher {
    @apply bg-blue-bright relative inline-flex flex-shrink-0 h-[2.875rem] w-1/2 rounded-full translate-x-0 transform transition ease-in-out duration-200;
  }

  .rx-button-switch.enabled .switcher {
    @apply translate-x-full;
  }

  .rx-button-switch .switch-container,
  .rx-multi-switch .switch-container {
    @apply absolute top-0 left-0 w-full h-full flex items-center pointer-events-none;
  }

  .rx-multi-switch {
    @apply relative;
  }

  .rx-multi-switch.period .switcher {
    @apply bg-blue-bright relative inline-flex flex-shrink-0 h-10 w-[25%] rounded-full cursor-pointer ring-0 transform transition ease-in-out duration-200;
  }

  .rx-multi-switch.period .switcher[data-value="day"],
  .rx-multi-switch.period .switcher[data-value="functions"] {
    @apply translate-x-0;
  }

  .rx-multi-switch.period .switcher[data-value="week"],
  .rx-multi-switch.period .switcher[data-value="greeting"] {
    @apply translate-x-[100%];
  }

  .rx-multi-switch.period .switcher[data-value="month"],
  .rx-multi-switch.period .switcher[data-value="schedules"] {
    @apply translate-x-[200%];
  }

  .rx-multi-switch.period .switcher[data-value="year"],
  .rx-multi-switch.period .switcher[data-value="settings"] {
    @apply translate-x-[300%];
  }

  .rx-multi-switch.period .switch-button {
    @apply relative inline-flex flex-shrink-0 h-10 w-[25%] items-center justify-center p-3 border border-transparent leading-5 rounded-sm text-dark transition transition-colors duration-200;
  }

  .rx-multi-switch .switch-button.enabled {
    @apply text-white;
  }
}
