@layer components {
  .rx-date-picker.open .rx-calendar {
    @apply block opacity-100;
  }

  .rx-calendar {
    @apply absolute hidden mt-1 p-4 w-full bg-white rounded-xl text-slate sm:max-w-xs overflow-hidden
      border border-iceberg-dark sm:text-sm opacity-0 z-40 transition ease-in duration-100;
  }

  .rx-calendar:focus {
    @apply outline-none;
  }

  .rx-calendar .day-header {
    @apply text-slate-light text-center font-medium;
  }

  .rx-calendar .period {
    @apply text-slate-light text-center p-1 rounded;
  }

  .rx-calendar .period.current {
    @apply text-slate;
  }

  .rx-calendar .period.selected {
    @apply bg-blue-bright rounded text-white;
  }

  .rx-calendar .period.today {
    @apply font-medium;
  }

  .rx-calendar .timepicker-grid {
    grid-template-columns: minmax(0, 1fr) auto minmax(0, 1fr) auto;
  }
}
