@layer components {
  .rx-radio {
    @apply w-4 h-4 border-2 border-white outline outline-2 outline-slate-light rounded-full;
  }

  .rx-radio:focus {
    @apply ring-2 ring-offset-2;
  }

  .rx-radio.selected {
    @apply bg-blue-bright outline-blue-bright;
  }
}
