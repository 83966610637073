.ct-series-a .ct-bar {
  stroke: #0364ff;
}

.ct-series-a .ct-slice-pie {
  fill: #0364ff;
}

.ct-series-b .ct-slice-pie {
  fill: #038eff;
}

.ct-series-c .ct-slice-pie {
  fill: #2503ff;
}

.ct-series-d .ct-slice-pie {
  fill: #7903ff;
}

.ct-series-e .ct-slice-pie {
  fill: #cd03ff;
}

.ct-label {
  fill: #535875;
}
.text-red {
  color: red !important;
}
.hr-color {
  color: #80A4D6;
}
.font-21 {
  font-size: 21px !important;
}
.h-40{
  height: 40rem;
}
.break-all {
  word-break:  break-all;
}
