@import 'fonts.css';

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import 'layout.css';
@import 'typography.css';
@import 'forms.css';
@import 'buttons.css';
@import 'tables.css';
@import 'misc.css';

@import 'components/layout/list-container.css';

@import 'components/form-elements/badge.css';
@import 'components/form-elements/radio.css';
@import 'components/form-elements/tooltip.css';
@import 'components/form-elements/tag-list.css';
@import 'components/form-elements/date-picker.css';
@import 'components/form-elements/switch-button.css';

@import 'components/page-elements/panel-group.css';
@import 'components/page-elements/modal-dialog.css';
@import 'components/page-elements/media-player.css';
@import 'components/page-elements/zoom-control.css';
@import 'components/page-elements/tabs-control.css';
@import 'components/page-elements/pagination-control.css';
@import 'components/page-elements/flash-notifications.css';
