@layer components {
  .pagination-list {
    @apply my-4 flex items-center justify-center md:justify-end items-center justify-end gap-2;
  }

  .pagination-list .page {
    @apply flex items-center justify-center basis-6 h-6 box-content rounded text-slate-light font-medium text-xs border border-slate-light transition-colors duration-200 bg-iceberg md:bg-transparent;
  }

  .pagination-list .page:hover {
    @apply bg-iceberg text-slate;
  }

  .pagination-list .page[disabled] {
    @apply text-slate-light cursor-default;
  }

  .pagination-list .page[disabled] {
    @apply bg-transparent;
  }

  .pagination-list .page.named {
    @apply bg-blue-bright text-white border-transparent;
  }

  .pagination-list .page.gap {
    @apply border-transparent;
  }

  .pagination-list .page.current {
    @apply font-bold text-white md:text-blue-bright bg-blue-bright md:bg-transparent border border-blue-bright;
  }

  .pagination-list .page:focus-visible {
    @apply  outline-none;
  }
}
