@layer components {
  .tabs-select {
    @apply block sm:hidden px-6 mt-6;
  }

  .tabs-select.compact {
    @apply hidden;
  }

  .tabs-menu {
    @apply hidden sm:block;
  }

  .tabs-menu.compact {
    @apply block;
  }

  .tabs {
    @apply -mb-px flex bg-iceberg;
  }

  .compact .tabs {
    @apply bg-white pb-6 gap-4;
  }

  .tab {
    @apply text-slate py-4 px-6 text-center font-normal text-base bg-transparent rounded-t-md;
  }

  .tab.active {
    @apply text-blue-bright bg-white;
  }

  .compact .tabs .tab {
    @apply p-0 border-b-2 border-transparent;
  }

  .compact .tabs .tab.active {
    @apply border-blue-bright;
  }

  .tab-container {
    @apply rounded-b-md;
  }

  .tab-panel {
    @apply hidden;
  }

  .tab-panel.active {
    @apply block;
  }

  .tab-step {
    @apply flex basis-1/4 text-slate-light font-medium bg-iceberg;
  }

  .tab-step .tab-label {
    @apply flex items-center justify-center py-4 px-6 grow;
  }

  .tab-step .arrow-left {
    @apply bg-iceberg border-l-[1rem] border-l-white border-t-[2.25rem] border-t-[#f5f6fa] border-b-[2.25rem] border-b-[#f5f6fa];
  }

  .tab-step .arrow-right {
    @apply bg-iceberg border-l-[1rem] border-l-[#f5f6fa] border-t-[2.25rem] border-t-white border-b-[2.25rem] border-b-white;
  }

  .tab-step.active {
    @apply text-white bg-blue-bright;
  }

  .tab-step.active .arrow-left {
    @apply bg-iceberg border-l-[1rem] border-l-white border-t-[2.25rem] border-t-[#0364ff] border-b-[2.25rem] border-b-[#0364ff];
  }

  .tab-step.active .arrow-right {
    @apply bg-iceberg border-l-[1rem] border-l-[#0364ff] border-t-[2.25rem] border-t-white border-b-[2.25rem] border-b-white;
  }

  .tab-step:first-child {
    @apply rounded-l-xl;
  }

  .tab-step:last-child {
    @apply rounded-r-xl;
  }

  .tab-step:first-child .arrow-left {
    @apply hidden;
  }

  .tab-step:last-child .arrow-right {
    @apply hidden;
  }
}
