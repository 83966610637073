@layer components {
  .tag {
    @apply flex gap-4 items-center px-4 py-1 bg-blue-bright text-white font-medium rounded-full;
  }

  .tag.error {
    @apply bg-red-orange;
  }

  .tag.default {
    @apply bg-slate-light text-slate;
  }

  .tag.dark {
    @apply bg-iceberg-badge text-slate;
  }

  .tag[draggable="true"] {
    @apply cursor-move;
  }
}
