@layer components {
  .modal .modal-overlay {
    @apply fixed inset-0 bg-slate bg-opacity-0 transition-all duration-500 md:duration-200;
  }

  .modal.open .modal-overlay {
    @apply bg-opacity-50;
  }

  .modal .modal-body {
    @apply inline-block align-bottom bg-white mt-20 md:mt-0 md:rounded-lg p-4 text-left overflow-visible transform transform-gpu transition-all md:align-middle md:max-w-lg w-full -translate-x-full md:translate-x-0 md:opacity-0 md:scale-75 duration-500 md:duration-200;
  }

  .modal.mobile .modal-body {
    @apply mt-0;
  }

  .modal.open .modal-body {
    @apply translate-x-0 opacity-100 md:scale-100;
  }

  .modal.w-l .modal-body {
    @apply max-w-2xl;
  }

  .modal.w-xl .modal-body {
    @apply max-w-4xl;
  }

  .modal.w-sm .modal-body {
    @apply max-w-sm;
  }
}
