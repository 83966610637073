@layer utilities {
  .grid-rows-0 {
    grid-template-rows: 0fr;
  }

  .grid-rows-full {
    grid-template-rows: 1fr;
  }

  .basis\/3 {
    flex-basis: calc(33.33% - 1rem / 3);
  }
}

@layer components {
  .panel-group {
    @apply mb-4;
  }

  .panel-group .collapsible {
    @apply grid grid-rows-0 transition-all duration-200 overflow-hidden;
  }

  .panel-group.open .collapsible {
    @apply grid-rows-full overflow-visible;
  }

  .panel-group .collapsible-content {
    @apply min-h-0;
  }
}
