@layer components {
  table {
    @apply min-w-full;
  }

  thead {
    @apply hidden md:table-header-group;
  }

  th {
    @apply pr-2 py-2 text-left font-medium text-base text-slate-light uppercase tracking-wider;
  }

  tfoot th {
    @apply font-semibold text-dark capitalize;
  }

  tr {
    @apply flex flex-col md:table-row;
  }

  td {
    @apply pr-2 py-2 md:py-2 whitespace-nowrap;
  }

  .t-row {
    @apply flex flex-col gap-4 px-0 md:px-4 py-4 md:py-5 border-b border-iceberg-badge;
  }

  .t-row:last-child {
    @apply md:border-b-0;
  }

  .thead {
    @apply hidden md:block;
  }

  .thead .t-row {
    @apply flex flex-row py-2;
  }

  .t-cell,
  .t-hcell {
    @apply basis-1/6 mb-2 md:mb-0;
  }

  .t-ext {
    @apply grid grid-cols-6 gap-x-6 gap-y-4;
  }

  .tbody {
    @apply rounded md:outline outline-1 outline-iceberg-badge;
  }

  .tbody .t-row:nth-child(even) {
    @apply md:bg-iceberg;
  }

  .list-table tbody {
    @apply rounded md:outline outline-1 outline-iceberg-badge;
  }

  .list-table tr {
    @apply border-b border-iceberg-badge mb-8;
  }

  .list-table tr:last-child {
    @apply border-0;
  }

  .list-table tr:nth-child(even) {
    @apply md:bg-iceberg;
  }

  .list-table.mobile tr {
    @apply table-row;
  }

  .list-table tr.active {
    @apply bg-iceberg;
  }

  .list-table tr[draggable="true"] {
    @apply cursor-row-resize;
  }

  .list-table th:first-child {
    @apply pl-4;
  }

  .list-table td {
    @apply md:py-5;
  }

  .list-table.compact td {
    @apply py-1;
  }

  .list-table.mobile td {
    @apply py-5;
  }

  .list-table td.nowrap {
    @apply whitespace-normal;
  }

  .list-table td:first-child {
    @apply md:pl-4;
  }
  table.plan-table td {
    @apply whitespace-normal
  }
  table.plan-table th {
    @apply text-[15px];
  }
}
