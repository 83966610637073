@font-face {
  font-family: Rubik;
  src: local(Rubik-Light),
    url(../fonts/rubik-latin-300.woff2) format('woff2'),
    url(../fonts/rubik-latin-300.woff) format('woff'),
    url(../fonts/rubik-latin-300.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Rubik;
  src: local(Rubik-Light),
    url(../fonts/rubik-latin-regular.woff2) format('woff2'),
    url(../fonts/rubik-latin-regular.woff) format('woff'),
    url(../fonts/rubik-latin-regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Rubik;
  src: local(Rubik-Light),
    url(../fonts/rubik-latin-500.woff2) format('woff2'),
    url(../fonts/rubik-latin-500.woff) format('woff'),
    url(../fonts/rubik-latin-500.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}
