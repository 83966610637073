@layer utilities {
  .basis\/3 {
    flex-basis: calc(33.33% - 1rem / 3);
  }
}

@layer components {
  main {
    @apply max-w-screen-xl h-full w-full lg:rounded bg-white;
  }

  #page {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: minmax(0, auto) 1fr;
    min-height: 100vh;
  }

  nav.top {
    @apply w-full;
  }

  nav.top a {
    @apply block border-b-4 py-3 border-transparent text-white-gray text-base font-medium whitespace-nowrap;
  }

  nav.top a:hover {
    @apply text-white;
  }

  nav.top a.active {
    @apply text-white border-blue-bright;
  }

  nav.top .submenu {
    @apply absolute z-40 mt-5 -ml-4 w-screen max-w-max origin-top duration-200 ease-in-out opacity-0 transform transform-gpu scale-y-0;
  }

  nav.top .submenu.open {
    @apply opacity-100 scale-y-100;
  }

  nav.mobile {
    @apply absolute z-40 origin-top duration-200 ease-in-out opacity-0 transform transform-gpu scale-y-0 w-full bg-dark;
  }

  nav.mobile.open {
    @apply opacity-100 scale-y-100;
  }

  nav.mobile a {
    @apply block p-4 text-white-gray text-base font-medium transition-colors duration-200;
  }

  nav.mobile a.has-child {
    @apply flex;
  }

  nav.mobile a:hover {
    @apply text-white bg-slate;
  }

  nav.mobile .submenu {
    @apply transition-transform origin-top duration-200 ease-in-out transform transform-gpu scale-y-0 h-0;
  }

  nav.mobile .submenu.open {
    @apply opacity-100 scale-y-100 h-auto;
  }

  nav.mobile .submenu a {
    @apply pl-8;
  }

  nav.settings {
    @apply flex flex-col;
  }

  nav.settings a {
    @apply text-slate text-base px-6 py-4;
  }

  nav.settings a:hover,
  nav.settings a.active {
    @apply text-blue-bright bg-white;
  }

  nav.settings a {
    @apply border-l-4 border-transparent;
  }

  nav.settings a.active {
    @apply border-blue-bright;
  }

  header .notification {
    @apply text-white-gray w-20 h-20 items-center justify-center border-l border-r border-slate transition-colors duration-200;
  }

  header .notification:hover {
    @apply text-white;
  }

  header .account {
    @apply text-white-gray h-20 w-28 flex gap-4 items-center justify-center border-l border-slate transition-colors duration-200;
  }

  header .account:hover {
    @apply text-white;
  }

  header .account .avatar {
    @apply w-11 h-11 rounded-full;
  }

  header .account .submenu {
    @apply absolute z-40 mt-1 w-full origin-top duration-200 ease-in-out opacity-0 transform transform-gpu scale-y-0;
  }

  header .account .submenu.open {
    @apply opacity-100 scale-y-100;
  }

  header .account a {
    @apply block border-b-4 py-3 border-transparent text-white-gray text-sm font-medium;
  }

  header .account a:hover {
    @apply text-white;
  }

  header .avatar {
    @apply inline-flex items-center justify-center h-6 w-6 rounded-full bg-blue-bright;
  }

  header .avatar-thumb {
    @apply rounded-full;
  }

  header .avatar .initials {
    @apply text-2xl font-medium leading-none text-white;
  }

  .form-container {}

  .field-group {
    @apply m-0 sm:m-2 p-0 sm:p-4 mb-4;
  }

  .avatar {
    @apply inline-flex items-center justify-center bg-blue-bright w-11 h-11 rounded-full;
  }

  .avatar.large {
    @apply w-[4.5rem] h-[4.5rem];
  }

  .avatar .initials {
    @apply text-xl font-medium leading-none text-white;
  }

  .avatar .avatar-thumb {
    @apply rounded-full;
    width: 70px;
    height: 70px;
    object-fit: cover;
  }

  .avatar .avatar-thumb.avatar-thumb-small {
    @apply rounded-full;
    width: 44px;
    height: 44px;
  }

  .avatar.large .initials {
    @apply text-4xl;
  }

  .active-shift-days {
    @apply flex flex-row gap-1;
  }

  .week-container {
    @apply grid;
  }

  .week-container.calendar {
    @apply grid-cols-7 gap-[1px];
  }

  .week-container.list {
    @apply grid-cols-1 gap-4;
  }

  .week-container .week-day {
    @apply outline outline-1 outline-iceberg-dark p-2 flex gap-2;
  }

  .week-container.list .week-day {
    @apply col-span-7 flex-row;
  }

  .week-container.calendar .week-day {
    @apply col-span-7 lg:col-span-1 flex-col;
  }

  .week-container .week-day .header {
    @apply font-medium text-slate-light uppercase flex flex-col justify-center;
  }

  .week-container.list .week-day .header {
    @apply p-4 basis-40;
  }

  .week-container .week-day.active .header {
    @apply text-blue-bright;
  }

  .week-container .day-container {
    @apply flex flex-wrap basis-full gap-2;
  }

  .week-container.list .day-container {
    @apply flex-row;
  }

  .week-container.calendar .day-container {
    @apply flex-col;
  }

  .week-container.list .day-container .badge {
    @apply basis-full sm:basis/3;
  }

  .week-container.calendar .day-container .badge {
    @apply basis-auto;
  }

  .week-container .week-day .week-day-date {
    @apply text-xs text-slate;
  }

  .week-container .week-day.active .week-day-date {
    @apply text-blue-bright;
  }

  .month-day {
    @apply flex flex-col text-xs text-slate-light p-2 gap-2;
  }

  .month-day.list {
    @apply col-span-7 h-auto outline-none;
  }

  .month-day.list .month-day-date {
    @apply text-base font-medium text-dark;
  }

  .month-day.list .month-day-date.active.current-month {
    @apply text-blue-bright;
  }

  .month-day.calendar {
    @apply col-span-7 lg:col-span-1 print:col-span-1 lg:print:col-span-1 min-h-[10rem] outline outline-iceberg-dark outline-[1px] transition-colors duration-200;
  }

  .month-day.calendar:hover {

  }

  .month-day.header {
    @apply text-base font-medium uppercase bg-slate-light text-white h-auto px-2 py-4 outline outline-iceberg-dark outline-[1px];
  }

  .month-day.header.active {
    @apply bg-blue-bright;
  }

  .month-day.header:nth-child(1) {
    @apply rounded-tl-sm;
  }

  .month-day.header:nth-child(7) {
    @apply rounded-tr-sm;
  }

  .month-day.calendar .empty {
    @apply hidden;
  }

  .month-day.calendar:nth-child(n-7) {
    @apply rounded-bl-sm;
  }

  .month-day.calendar:last-child {
    @apply rounded-br-sm;
  }

  .month-day.calendar .action {
    @apply flex grow justify-center mb-6;
  }

  .month-day.calendar .add {
    @apply text-transparent transition-colors outline-none duration-200;
  }

  .month-day.calendar .month-day-date {
    @apply text-base font-medium;
  }

  .month-day.calendar .month-day-date.current-month {
    @apply text-dark;
  }

  .month-day.calendar .month-day-date.active.current-month {
    @apply text-blue-bright;
  }

  .month-day.calendar:hover .add {
    @apply text-blue-bright;
  }

  .month-day.calendar:hover .shift .month-day-date {
    @apply text-dark;
  }

  .card {
    @apply flex flex-col gap-y-2 items-center rounded-lg p-4;
  }

  .card.blue {
    @apply border border-4 border-blue-bright bg-badge-blue;
  }

  .spinner {
    @apply flex justify-center content-center hidden;
  }

  .spinner.enabled {
    @apply flex;
  }

}

.outline-pannel {
  box-shadow: 0 0 0 1px #e2e3e8;
  margin: 2px;
}

.layout-bg {
  background-size: cover;
  background-image: url('../images/bg.png');

@media (min-width: 640px) {
background-image: url('../images/bg-sm.png');
}

  @media (min-width: 768px) {
  background-image: url('../images/bg-md.png');
  }

  @media (min-width: 1024px) {
  background-image: url('../images/bg-lg.png');
  }

  @media (min-width: 1280px) {
  background-image: url('../images/bg-xl.png');
  }

  @media (min-width: 1536px) {
  background-image: url('../images/bg-2xl.png');
  }

  @media (min-width: 2560px) {
  background-image: url('../images/bg-4xl.png');
  }
}
