@layer components {
  .audio-container {
    @apply flex flex-wrap items-center gap-4;
  }

  .audio-container .button {
    @apply text-blue-bright;
  }

  .audio-container button:focus-visible {
    @apply outline-none;
  }

  .audio-container input[type="range"] {
    @apply appearance-none rounded-lg h-4 bg-iceberg border border-iceberg-dark;
  }

  .audio-container input[type="range"]:focus-visible {
    @apply outline-none border-blue-bright;
  }

  .audio-container input[type="range"]::-webkit-slider-runnable-track {
    @apply px-px;
  }

  .audio-container input[type="range"]::-webkit-slider-thumb {
    @apply appearance-none cursor-pointer bg-blue-bright h-2.5 w-6 rounded-lg border-2 border-iceberg;
  }

  .audio-container input[type="range"]:disabled::-webkit-slider-thumb {
    @apply bg-iceberg-dark;
  }

  .audio-container input[type="range"]::-moz-range-thumb {
    @apply appearance-none cursor-pointer bg-blue-bright h-2.5 w-6 rounded-lg border-2 border-iceberg;
  }

  .audio-container input[type="range"]:disabled::-moz-range-thumb {
    @apply bg-iceberg-dark;
  }

  .audio-container .control-button {
    @apply text-blue-bright;
  }

  .audio-container .control-button[disabled] {
    @apply text-slate-light;
  }

  .audio-container .control-button.record {
    @apply text-red-orange;
  }
}
