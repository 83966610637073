@layer components {
  .flash-list {
    @apply fixed z-50 inset-0 flex items-start mt-5 pointer-events-none md:p-6;
  }

  .flash {
    @apply md:max-w-sm w-full bg-white pointer-events-auto border-blue-bright border-0 border-b md:border transform transform-gpu transition-all duration-500 md:duration-200 -translate-x-full md:translate-x-0 md:opacity-0 md:scale-75 relative;
  }

  .flash.wide {
    @apply md:max-w-lg w-full;
  }

  .flash.open {
    @apply translate-x-0 md:opacity-100 md:scale-100;
  }

  .flash .flash-type {
    @apply flex-shrink-0 flex items-center justify-center text-dark;
  }

  .flash .flash-content {
    @apply w-0 flex-1 text-sm text-dark;
  }

  .flash .flash-close {
    @apply bg-blue-bright text-white w-6 h-5 rounded-full flex justify-center focus:outline-none;
  }

  .dialog {
    @apply md:max-w-sm w-full md:rounded-lg pointer-events-auto bg-white border border-iceberg-dark transform transform-gpu transition-all duration-500 md:duration-200 -translate-x-full md:translate-x-0 md:opacity-0 md:scale-75;
  }

  .dialog.open {
    @apply translate-x-0 md:opacity-100 md:scale-100;
  }

  .dialog .flash-type {
    @apply text-blue-bright mx-auto flex-shrink-0 flex items-center justify-center sm:mx-0;
  }

  .dialog .flash-content {
    @apply ml-3 w-0 flex-1 text-sm;
  }

  .dialog .flash-close {
    @apply bg-blue-bright text-white w-6 h-5 rounded-full flex justify-center focus:outline-none;
  }
}
